/**
 * @description This is the common util module that is used
 * throught the application.
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @module util/commonUtil
 */

/**
 * @description Takes an array of items and turn them into a list of strings.
 * This also cleans up the input and removes empty string, nulls and undefined
 * @since 1.0.0
 * @param {Array} data strings
 * @returns a concatination of trimmed strings with the nulls, undefined filtered out
 */
export function filterAndJoinResults(data) {
  if (!data || !Array.isArray(data) || data.length === 0) {
    return null;
  }

  //filter out all the null values
  const filteredData = data.filter(
    item => item && typeof item === "string" && item.trim()
  );

  const trimmedData = filteredData.map(item => item.trim());

  //merge all the results
  const mergedData = trimmedData.join();

  //return null if data is empty
  if (!mergedData || mergedData.length === 0) return null;

  return mergedData;
}

/**
 * @description get an enum based on its object key
 * @since 1.0.0
 * @param {object} enumType the enum that you want to search
 * @param {*} key the key you want to map back to
 * @example
 * getEnumFromKey(CONTACT_TYPES, "aaisDirectCompanyAdmin");
 * //this returns CONTACT_TYPES.AAIS_DIRECT_COMPANY_ADMIN
 */
export function getEnumFromKey(enumType, key) {
  const enumNames = Object.keys(enumType ? enumType : {});
  for (const enumName of enumNames) {
    if (enumType[enumName].key === key) {
      return enumType[enumName];
    }
  }
  return null;
}

export function fixMaterialTypeForAPI(packageTypeCategories, packageTypes, packageObjects){
  for(const pkgCategory in packageTypeCategories){
    for(const pkgObj in packageObjects){
      if(packageTypeCategories[pkgCategory] === packageObjects[pkgObj]['title']){
        if(!packageObjects[pkgObj]['types'] || (packageObjects[pkgObj]['types'] && packageObjects[pkgObj]['types'].length === 0) ){
          let singularTitle = packageObjects[pkgObj]['title'];
          if (singularTitle.slice(-1) === 's'){
            singularTitle = singularTitle.slice(0,-1);
          }
          packageTypes.push(singularTitle);
        }
        else{
          const allLower = [];
          let thisTypeEmpty = true;
          for(const subTypeI in packageObjects[pkgObj]['types']){
            allLower.push(packageObjects[pkgObj]['types'][subTypeI].title);
          }
          for(const subTypeI in packageTypes){
            if(allLower.includes(packageTypes[subTypeI])){
              thisTypeEmpty = false;
            }
          }
          if(thisTypeEmpty === true){
            for(const subTypeI in allLower){
              packageTypes.push(allLower[subTypeI]);
            }
          }

        }
      }
    }
  }
  return packageTypes; 
}

export function demuxMaterialTypes(apiPackageTypes, supportingDocsType){
  const fixedPackageTypes = [];
  const fixedSupportingPackageTypes = [];
    for(const pkgTypeCtg in apiPackageTypes){
      fixedPackageTypes.push(apiPackageTypes[pkgTypeCtg]['title']);
      if(!apiPackageTypes[pkgTypeCtg]['types'] || (apiPackageTypes[pkgTypeCtg]['types'] && apiPackageTypes[pkgTypeCtg]['types'].length === 0) ){
        let singularTitle = apiPackageTypes[pkgTypeCtg]['title'];
        if (singularTitle.slice(-1) === 's'){
          singularTitle = singularTitle.slice(0,-1);
        }
        fixedSupportingPackageTypes.push(singularTitle);
      }
    }
    for(const pkgType in supportingDocsType){
      if(supportingDocsType[pkgType]['bot']){
        for(const pkgTypeTitle in supportingDocsType[pkgType]['bot']){
          fixedSupportingPackageTypes.push(supportingDocsType[pkgType]['bot'][pkgTypeTitle]);
        }
      }

    }
    if(fixedPackageTypes.includes('All Content')){
      fixedPackageTypes.length = 0;
      fixedSupportingPackageTypes.length = 0;
      fixedPackageTypes.push('All Content');
      fixedSupportingPackageTypes.push('All Content');
    }
    if(fixedSupportingPackageTypes.includes('All Content')){
      fixedSupportingPackageTypes.length = 0;
      fixedSupportingPackageTypes.push('All Content');
    }
    const data = [];
    data.push(fixedPackageTypes);
    data.push(fixedSupportingPackageTypes);
    return data;
}

export function fileNamesConvert(files){
  //TODO: need to make a file picker for ADMIN functions
  const names = files.map((fileObj) => {
    if(fileObj.extension === '.pdf'){
      return fileObj.filename;
    }
  });
  return names[0];

}
