// Packages
import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

// MUI Components
import {
  Container,
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  CardContent,
  Card,
  CardHeader,
  Hidden,
  CardActions,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListSubheader,
  Menu,
  MenuItem,
  ButtonGroup,
  ClickAwayListener,
} from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Badge from "@material-ui/core/Badge";
import Collapse from "@material-ui/core/Collapse";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

// MUI Icons
import FormIcon from "@material-ui/icons/ListAlt";
import CurrentIcon from "@material-ui/icons/Check";
import LossCostIcon from "@material-ui/icons/AttachMoney";
import FcInfo from "@material-ui/icons/Info";
import MenuIcon from "@material-ui/icons/Menu";
import NotCurrent from "@material-ui/icons/NotInterested";
import StatesIcon from "@material-ui/icons/Public";
import ProductsIcon from "@material-ui/icons/Category";
import RatingIcon from "@material-ui/icons/StarRate";
import RuleIcon from "@material-ui/icons/Gavel";
import SupportingDocIcon from "@material-ui/icons/AttachFile";
import FileDownloadIcon from '@material-ui/icons/CloudDownload';

// Components
import AAISPDFViewerDialog from "components/AAISPDFViewerDialog";
import FilePicker from "components/FilePicker";
import SingleResult from "components/BrowseResults/singleResult.js";
import PopUpWarning from "components/PopUpWarning";
import { fileNamesConvert } from "util/commonUtil";

// Styles
import { useStyles } from "./styles";

import * as AssetApi from "apis/AssetApi";

// Data
import LINES from "enums/LINES";
import PACKAGE_TYPES from "enums/PACKAGE_TYPES";
import STATES from "enums/STATES";

//too much of the UI is dependant on the OLD PACKAGE_TYPES to remove entirely.
import OLD_PACKAGE_TYPES from "enums/OLD_PACKAGE_TYPES";

export default function BrowseResults(props) {
  const classes = useStyles();
  const [previewItem, setPreviewItem] = useState();
  const [expandedCard, setExpandedCard] = useState();
  const [moreLine, setMoreLine] = useState(props.line);
  const [chosenFile, setChosenFile] = useState(null);
  const [filesArr, setFilesArr] = useState([]);
  const [moreInformation, setMoreInformation] = useState(false);
  const [indexId, setIndexId] = useState();
  const [indexTitle, setIndexTitle] = useState();
  const [indexFiles, setIndexFiles] = useState();
  const [indexCategory, setIndexCategory] = useState();
  const allStatesData = Object.values(STATES);
  const [thisFilesCountTop, setThisFilesCountTop] = useState();
  const [validPDFsTop, setValidPDFsTop] = useState();
  const [showPopupBlockerWarning, setShowPopupBlockerWarning] = useState(false);
  const [statesToDisplayChip, setStatesToDisplayChip] = useState([]);
  const minDisplay = props.minDisplay ? props.minDisplay : false;
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSort, setOpenSort] = useState(false);
  

  function resetPreview(){
    setPreviewItem();
    setChosenFile(null);
    setFilesArr([]);
  }
  function resetMoreInformation(){
    setMoreInformation(false);
  }

  async function setUpNewTabLink(files){
    const newUrls = {};
    await files.map( async (fileObj) => {
      if(fileObj.extension && fileObj.extension === '.pdf'){
        const response = await AssetApi.getSignedUrlV1(fileObj.filename);
        const encodedUrl = encodeURIComponent(response.url);
        //newUrls[fileObj.filename] = await window.open(`https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodedUrl}#zoom=auto#toolbar=0`, fileObj.filename);
        newUrls[fileObj.filename] = await window.open(`${response.url}#navpanes=0#zoom=auto#toolbar=0#&embedded=true`, fileObj.filename);
        if(!newUrls[fileObj.filename] || newUrls[fileObj.filename].closed || typeof newUrls[fileObj.filename]=='undefined'){ 
          setShowPopupBlockerWarning(true);
        }
      }
    });
  }

  function setupPreviewItem(source){
    const fixedArray = [];
    
    if( source && 'files' in source && source['files'] && source['files'].length > 0 ){
      source['files'].map((fileObj) => {
        if(fileObj.extension && fileObj.extension === '.pdf'){
          fixedArray.push(fileObj.filename);
        }
      });
      setFilesArr(fixedArray);
      if(fixedArray.length === 1){
        setChosenFile(fixedArray[0]);
      }
      else{// if(fixedArray.length > 1){
        //if there are multiple fileNames, initiate the File Picker popup
        setChosenFile('False'); 
      }
    }
    else{
      //if there are no fileNames, set chosenFile to null to prevent popup
      setChosenFile(null);
      setFilesArr([]);
    }
    setPreviewItem(source);
    setThisFilesCountTop(source['files'].length);
    setValidPDFsTop(source['files'].some(checkPDFext));
  }

  const checkPDFext = (item) => item.extension === ".pdf";//item.substr(item.length - 4) === ".pdf";
  function getItems() {
    if (!props.results || !props.results.hits || !props.results.hits.hits)
      return;

    return props.results.hits.hits.map((item, i) => {
      const thisFilesCount = item._source['files'].length;
      const validPDFs = item._source['files'].some(checkPDFext);
      const titleClass = validPDFs ? classes.title : classes.titleNoLink;
      return (
        <Paper key={i}>
        <ListItem className={classes.listItem} key={item._id} data-product={"product"}>
          <div className={classes.flexboxItemMain}>
            <span
              className={titleClass}
              color="primary"
              onClick={() => {
                setupPreviewItem(item._source);
              }}
            >
              {item._source.displayName ? item._source.displayName : item._source.publicationName}
            </span>
              {thisFilesCount > 0 && validPDFs > 0  && (<span> 
                  <Tooltip
                    title={`Open in new tab`}
                    PopperProps={{
                      "data-test": `browseResults-item-${i}-opennew-tooltip`,
                    }}
                    placement="top"
                  ><OpenInNewIcon className={classes.openInNewIcon} onClick={ async () => {
                await setUpNewTabLink(item._source['files']);
                }}></OpenInNewIcon></Tooltip></span>
                )}
          </div>
              <div className={classes.flexboxItemOther}>
                {item?.highlight?.text && (
                 
                  <div className={classes.cardContent}>
                    <div
                      className={classes.highlight}
                      dangerouslySetInnerHTML={getHighlight(item)}
                    ></div>
                  </div>
                )}
              </div>
              <div className={classes.flexboxRight}>
              <div className={classes.flexboxMaterialType}>
                {getTypeName(item, i)}
              </div>
              <div className={classes.flexboxItemSidebar}>
                  <Tooltip
                    title="Product"
                    PopperProps={{
                      "data-test": `browseResults-item-${i}-line-tooltip`,
                    }}
                    placement="top"
                  >
                    <Badge
                      badgeContent={getProductLineCount(item)}
                      data-test={`browseResults-item-${i}-line-badge`}
                      onClick={() => toggleExpanded(item)}
                      showZero
                    >
                      <ProductsIcon
                        className={classes.infoIcon}
                        color="disabled"
                        data-test={`browseResults-item-${i}-line-icon`}
                      />
                    </Badge>
                  </Tooltip>
                  <Tooltip
                    title={`Jurisdiction`}//title={`${props.line} states`}
                    PopperProps={{
                      "data-test": `browseResults-item-${i}-state-tooltip`,
                    }}
                    placement="top"
                  >
                    <Badge
                      badgeContent={getStateCount(item)}
                      data-test={`browseResults-item-${i}-state-badge`}
                      showZero
                    >
                      <StatesIcon
                        className={classes.infoIcon}
                        color="disabled"
                        data-test={`browseResults-item-${i}-state-icon`}
                        onClick={() => toggleExpanded(item)}
                      />
                    </Badge>
                  </Tooltip>
                  <Tooltip
                    title="More Info"
                    PopperProps={{
                      "data-test": `browseResults-item-${i}-moreinfo-tooltip`,
                    }}
                    placement="top">
                    <Badge
                      data-test={`browseResults-item-${i}-moreinfo-badge`}
                      showZero
                    >
                    <FcInfo
                      htmlColor="#1f4287"
                      className={classes.moreInfoButton}
                      onClick={async () => await toggleMoreInfo(item)}
                      data-test={`browseResults-item-${i}-more-info-icon`}
                    ></FcInfo>
                    </Badge>
                  </Tooltip>
                  <Tooltip
                    title="Download"
                    PopperProps={{
                      "data-test": `browseResults-item-${i}-download-tooltip`,
                    }}
                    placement="top">
                    <Badge
                        data-test={`browseResults-item-${i}-download-badge`}
                        showZero
                      >
                      <FileDownloadIcon
                        htmlColor="#1f4287"
                        data-test={`browseResults-item-${i}-download-icon`}
                        color="primary"
                        size="small"
                        className={classes.moreInfoButton}
                        onClick={async () => await downloadSingle(item)}
                      ></FileDownloadIcon>
                    </Badge>
                  </Tooltip>
              </div>
              </div>
              
        </ListItem>

        {expandedCard && item._id === expandedCard && (
          <Collapse in={true} timeout="auto" unmountOnExit>
            <Paper key={i+"smallTable"} className={classes.moreTable}>
              <div className={classes.moreTable}>
                <Divider />
                <Typography variant="subtitle1" align="left" style={{ paddingLeft:"5px" }}>
                  Product
                </Typography>
                <Paper className={classes.chipsPaper} elevation={0}>
                  {getProductLinesForItem(item, i)}
                </Paper>
                <Divider className={classes.divider} />
                <Typography variant="subtitle1" align="left" style={{ paddingLeft:"5px" }}>
                  Jurisdiction
                </Typography>
                <Paper className={classes.chipsPaper} elevation={0}>
                  {getStatesForLine(item)}
                </Paper>
              </div>
            </Paper>
          </Collapse>
        )}
        </Paper>
      );
    });
  }

  function getStateTitle(key){
    for(const x in allStatesData){
      if(allStatesData[x]['key'] === key){
        return allStatesData[x]['title'].toLowerCase().replace(" ", "");
      }
    }
    return "";
  }

  function convertMsToSeconds(time) {
    if (Number.isNaN(time)) return "Error";

    return (time / 1000).toFixed(2);
  }

  function getLineToolTip(item, line) {
    const lineName = LINES[line] ? LINES[line].title : "Line Unknown";
    if (item._source.lobs) {
      const states = item._source.lobs;
      if (Array.isArray(states)) {
        let statesString = '';
        for (let i = 0; i < states.length; i++) {
          statesString = statesString + states[i]['abbreviation'] + ', ';
        }
        statesString = statesString.slice(0,-2);
        return `${lineName} states (${states.length}): ${statesString}`;
      }

      return `${lineName} states (1): ${item._source.lobs[0].abbreviation}`;
    }
    return `${lineName} state (0)`;
  }

  function getProductLinesForItem(item, index) {
    if (item._source.lobs) {
      if (Array.isArray(item._source.lobs)) {

        const sortedLines = item._source.lobs.sort((a,b) => {
          if(a.abbreviation.toLowerCase() > b.abbreviation.toLowerCase()){
            return 1;
          }
          else if(a.abbreviation.toLowerCase() < b.abbreviation.toLowerCase()){
            return -1;
          }
          return 0;
        });

        return sortedLines.map((line) => {
          return (
            <Tooltip
              placement="top"
              title={getLineToolTip(item, line.abbreviation)}
              key={`${item._id}${line.abbreviation}`}
            >
              <Chip
                variant={moreLine === line.abbreviation ? "default" : "outlined"}
                color="primary"
                size="small"
                label={line.abbreviation}
                className={moreLine === line.abbreviation ? classes.chip : classes.chip}
                onClick={() => {
                  selectLineForState(line);
                }}
              />
            </Tooltip>
          );
        });
      }

      return (
        <Tooltip
          title={getLineToolTip(item, item._source.lobs[0].abbreviation)}
          placement="top"
        >
          <Chip
            key={`${item._source.productLines_ss}-line`}
            variant={
              moreLine === item._source.productLines_ss ? "default" : "outlined"
            }
            color="primary"
            size="small"
            label={item._source.productLines_ss}
            data-test={`browseResults-item-${index}-product-chip`}
          />
        </Tooltip>
      );
    }
  }

  function selectLineForState(line){
    setMoreLine(line.abbreviation);
    setStatesToDisplayChip(line.states);
  }

  function getStatesForLine(item) {
    const badStates = ['ALLSTATES', 'MULTISTATE', 'MU'];
    const dispStatesData = allStatesData.filter((item) => {
      return !badStates.includes(item.key);
    });
    const states = dispStatesData.sort((a,b) => { // item._source.states
      if(a.key.toLowerCase() > b.key.toLowerCase()){//abbreviation
        //if(getStateTitle(a.abbreviation) > getStateTitle(b.abbreviation)){
        return 1;
      }
      if(a.key.toLowerCase() < b.key.toLowerCase()){ //abbreviation
        //else if(getStateTitle(a.abbreviation) < getStateTitle(b.abbreviation)){
        return -1;
      }
      return 0;
    });
    if (statesToDisplayChip.length > 0) {
      return states.map((state) => {
        const isStateValid = statesToDisplayChip.filter((item) => {
          return item.abbreviation === state.key;
        });
        const stateChipClass = isStateValid.length > 0 ? classes.chipSelected : classes.chipNotFound; 
        return <Tooltip
          key={state.key}
          title={state.title}
          PopperProps={{
            "data-test": `line-item-${state.key}-jurisdiction-tooltip`,
          }}
          placement="top">
          <Chip
            key={state.key}
            variant="outlined"
            label={state.key}
            size="small"
            className={stateChipClass}
            data-test={`chip${state.key}`}
          />
        </Tooltip>;
      });
      
    }

    //const state = item._source[`aais_${moreLine}_Jur_ss`];
    let statesString = '';
    for (let i = 0; i < states.length; i++) {
      statesString = statesString + states[i]['abbreviation'] + ',';
    }
    statesString = statesString.slice(0,-1);

    if (statesString.length === 0) {
      return <Typography>{`No states found for ${moreLine}`}</Typography>;
    }

    return (
      <Chip
        variant="outlined"
        size="small"
        label={statesString}
        className={classes.chip}
        data-test={`chip${statesString}`}
      />
    );
  }

  function toggleExpanded(item) {
    setMoreLine(props.line); //always reset it back to default
    
    if (expandedCard === item._id) {
      setStatesToDisplayChip([]);
      setExpandedCard(null);
      return;
    }
    setStatesToDisplayChip(item._source.states);
    setExpandedCard(item._id);
  }

  async function toggleMoreInfo(item){
    setIndexId(item._id);
    setIndexTitle(item._source.displayName);
    setIndexFiles(item._source.files);
    setIndexCategory(item._source.publicationTypeCategory);
    await secondaryMoreInfo();
    
  }
  async function downloadSingle(item){
    const filesObj = item._source.files;
    props.setFilesArr(item);
    props.setDoSingleDownload(true);
    await props.setShowDisclaimerLanguageDialog(true);

  }
  async function secondaryMoreInfo(){
    await setMoreInformation(true);
  }

  function getTypeName(item, index){
    let publicationType = item?._source?.publicationTypeCategory;
    if(publicationType.slice(-1) === 's'){
      publicationType =  publicationType.slice(0,-1);
    }
    if(publicationType === 'Forms and Endorsements List'){
      publicationType = 'F&E List';
    }
    if(publicationType === 'IMG Publication'){
      publicationType = item?._source?.publicationType[0];
    }
    

    //return publicationType;
    return (
      <Tooltip
        title="Material Type"
        PopperProps={{
          "data-test": `browseResults-item-${index}-materialtype-tooltip`,
        }}
        placement="top">
        <span className={classes.materialType}>
          {publicationType}
        </span>
      </Tooltip>
    );
  }
  function getTypeIcon(item, index) {
    const publicationType = item?._source?.publicationType;
    //publicationType is an array
    let foundPubTypeCategory = null;
    for(const x in PACKAGE_TYPES){
      if('types' in PACKAGE_TYPES[x]){
        for(const y in PACKAGE_TYPES[x]['types']){
          if(publicationType.includes(PACKAGE_TYPES[x]['types'][y]['title'])){
            foundPubTypeCategory = PACKAGE_TYPES[x]['title'];
          }
        }
      }
      else{
          const singularTitle = publicationType[0];
          if (singularTitle.slice(-1) === 's'){
            foundPubTypeCategory = singularTitle.slice(0,-1);
          }
          else{
            foundPubTypeCategory = singularTitle;
          }
      }
    }
    if(!foundPubTypeCategory){
      return null;
    }

    switch (foundPubTypeCategory) {
      case "Forms":
      case "Form":
      case "PFM": {
        return (
          <Tooltip
            title={OLD_PACKAGE_TYPES.FORMS.title}
            PopperProps={{
              "data-test": `browseResults-item-${index}-form-tooltip`,
            }}
            placement="top"
          >
            <FormIcon
              color="disabled"
              data-test={`browseResults-item-${index}-form-icon`}
            ></FormIcon>
          </Tooltip>
        );
      }
      case "MANUAL_MATERIALS":
      case "PLC": {
        return (
          <Tooltip
            title={OLD_PACKAGE_TYPES.REPORTS.types.CAUSE_OF_LOSS_REPORT.title}
            PopperProps={{
              "data-test": `browseResults-item-${index}-lossCost-tooltip`,
            }}
            placement="top"
          >
            <LossCostIcon
              color="disabled"
              data-test={`browseResults-item-${index}-lossCost-icon`}
            ></LossCostIcon>
          </Tooltip>
        );
      }
      case "PRI": {
        return (
          <Tooltip
            title={OLD_PACKAGE_TYPES.IMG_PUBLICATIONS.types.RATING.title}
            PopperProps={{
              "data-test": `browseResults-item-${index}-ratingInformation-tooltip`,
            }}
            placement="top"
          >
            <RatingIcon
              color="disabled"
              data-test={`browseResults-item-${index}-ratingInformation-icon`}
            ></RatingIcon>
          </Tooltip>
        );
      }
      case "PRL": {
        return (
          <Tooltip
            title={OLD_PACKAGE_TYPES.IMG_PUBLICATIONS.types.RULES.title}
            PopperProps={{
              "data-test": `browseResults-item-${index}-rules-tooltip`,
            }}
            placement="top"
          >
            <RuleIcon
              color="disabled"
              data-test={`browseResults-item-${index}-rules-icon`}
            ></RuleIcon>
          </Tooltip>
        );
      }
      case "SUP":
      case "IMS": {
        return (
          <Tooltip
            title={OLD_PACKAGE_TYPES.IMG_PUBLICATIONS.title}
            PopperProps={{
              "data-test": `browseResults-item-${index}-supportingInformation-tooltip`,
            }}
            placement="top"
          >
            <SupportingDocIcon
              color="disabled"
              data-test={`browseResults-item-${index}-supportingInformation-icon`}
            ></SupportingDocIcon>
          </Tooltip>
        );
      }
      default: {
        return null;
      }
    }
  }

  function getStatusIcon(item, index) {
    switch (item?._source?.status_s) {
      case "Externally Approved": {
        return (
          <Tooltip
            title="Current"
            PopperProps={{
              "data-test": `browseResults-item-${index}-externallyApproved-tooltip`,
            }}
            placement="top"
          >
            <CurrentIcon
              className={classes.currentIcon}
              data-test={`browseResults-item-${index}-externallyApproved-icon`}
            ></CurrentIcon>
          </Tooltip>
        );
      }
      case "Superseded": {
        return (
          <Tooltip
            title="Not Current"
            PopperProps={{
              "data-test": `browseResults-item-${index}-superseded-tooltip`,
            }}
            placement="top"
          >
            <NotCurrent
              className={classes.notCurrentIcon}
              data-test={`browseResults-item-${index}-superseded-icon`}
            ></NotCurrent>
          </Tooltip>
        );
      }
      default: {
        return null;
      }
    }
  }

  function getProductLineCount(item) {
    if (item._source.lobs) {
      if (Array.isArray(item._source.lobs)) {
        return item._source.lobs.length;
      }

      return 1;
    }

    return 0;
  }

  function getStateCount(item) {
    if (item._source.states) {
      const states = item._source.states;
      if (Array.isArray(states)) {
        return states.length;
      }

      return 1;
    }

    return 0;
  }

  function getFormInfo(item, index) {
    let label = "";

    if (item._source.publicationType === "PFM") {
      label = `${item._source.documentNumber} ${item._source.edition}`;
    } else if (
      item._source.publicationType === "PLC" ||
      item._source.publicationType === "PRI" ||
      item._source.publicationType === "PRL" ||
      item._source.publicationType === "BUL"
    ) {
      label = `${item._source.documentNumber} ${item._source.edition}`;
    }

    return label;
  }

  const handleClickSort = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenSort(!openSort);
  };
  const handleCloseSort = (event) => {
    props.setSort(event);
    setAnchorEl(null);
    setOpenSort(null);
  };
  const handleCloseSortTop = (event) => {
    setAnchorEl(null);
    setOpenSort(false);
  };

  function getHighlight(item) {
    if (
      item.highlight &&
      item.highlight.text &&
      item.highlight.text.length > 0
    ) {
      const text = item.highlight.text.join("   ");
      return { __html: text };
    }
    return { __html: "" };
  }

  return (
    <React.Fragment>
      <Container maxWidth={true} disableGutters className={classes.browseToolBar}>
        <Grid container>
          <Grid item xs={12} className={classes.grow}>
            {!minDisplay && (<AppBar position="static" color="inherit">
              <Toolbar>
                <Hidden mdUp>
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={() => props.setDrawerOpen(true)}
                  >
                    <MenuIcon />
                  </IconButton>
                </Hidden>
               
                {!minDisplay && props?.results?.hits ? (
                  
                  <Grid container direction="row" xs={12}>
                    <Grid item xs={12}>
                      { props?.results && (props?.results?.hits?.total?.value ?? -1) >= 1000 && 
                      <Tooltip className={classes.tooltip} title="Search results must be less than 1,000 to download. Please filter your results further."><span>
                        <Button
                          className={classes.downloadButton}
                          color="primary"
                          variant="outlined"
                          data-test="browseScreen-item-download-button"
                          onClick={props.download}
                          disabled={true}
                        >
                          Download All
                        </Button></span>
                      </Tooltip>}
                      { props?.results &&  (props?.results?.hits?.total?.value ?? -1 ) < 1 && 
                      <Tooltip className={classes.tooltip} title="No results found."><span>
                        <Button
                          className={classes.downloadButton}
                          color="primary"
                          variant="outlined"
                          data-test="browseScreen-item-download-button"
                          onClick={props.download}
                          disabled={true}
                        >
                          Download All
                        </Button></span>
                      </Tooltip>}
                      { props?.results &&  (props?.results?.hits?.total?.value ?? -1) < 1000 && (props?.results?.hits?.total?.value ?? -1) > 0 && 
                        <Button
                          className={classes.downloadButton}
                          color="primary"
                          variant="outlined"
                          data-test="browseScreen-item-download-button"
                          onClick={props.download}

                        >
                          Download
                        </Button>}
                      { props?.results &&  (<Button
                        className={classes.requestHelpButton}
                        color="primary"
                        variant="outlined"
                        data-test="browseScreen-item-product-request-button"
                        onClick={props.handleRequestModal}
                      >
                        REQUEST HELP
                      </Button> )}
                      <span className={classes.hits}>{`${
                        props.results.hits.total.value
                      } results (${convertMsToSeconds(
                        props.results.took
                      )} seconds)`}</span>
                    
                    </Grid>
                  </Grid>
                  ) : (
                  <div className={classes.grow}></div>)}
                <ClickAwayListener
                  onClickAway={handleCloseSortTop}
                >
                <ButtonGroup
                  id={"browseScreen-sort-buttongroup"}
                  name={"browseScreen-sort-buttongroup"}
                  data-test={"browseScreen-sort-buttongroup"}
                  variant="menu"
                  ref={anchorEl}
                  aria-label="Button group with a nested menu"
                  className={classes.sortButton}
                  color="primary"
                >
                  <Button
                    className={classes.sortButton}
                    color="primary"
                    variant="outlined"
                    id={"browseScreen-sort-button"}
                    name={"browseScreen-sort-button"}
                    data-test={"browseScreen-sort-button"}
                    onClick={handleClickSort}
                    aria-controls={openSort ? 'demo-positioned-menu' : undefined}
                    aria-expanded={openSort ? 'true' : undefined}

                  >
                    Sort
                  </Button>
                  <ArrowDropDownIcon
                    onClick={handleClickSort}
                    className={classes.sortArrow}
                    id={"browseScreen-sort-arrow-button"}
                    name={"browseScreen-sort-arrow-button"}
                    data-test="browseScreen-sort-arrow-button"
                  />
                  { openSort ? (<Menu
                    id={"browseScreen-sort-menu"}
                    name={"browseScreen-sort-menu"}
                    data-test={"browseScreen-sort-menu"}
                    anchorEl={anchorEl}
                    open={openSort}
                    onClose={handleCloseSortTop} 
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    
                    <MenuItem
                      id={"browseScreen-sort-menu-item-newest"}
                      data-test={"browseScreen-sort-menu-item-newest"}
                      name={"browseScreen-sort-menu-item-newest"}
                      selected={props.sort === 'newest'}
                      onClick={() => handleCloseSort('newest')}>Date (Newest)
                    </MenuItem>
                    <MenuItem
                      id={"browseScreen-sort-menu-item-oldest"}
                      data-test={"browseScreen-sort-menu-item-oldest"}
                      name={"browseScreen-sort-menu-item-oldest"}
                      selected={props.sort === 'oldest'}
                      onClick={() => handleCloseSort("oldest")}>Date (Oldest)
                    </MenuItem>
                    <MenuItem
                      id={"browseScreen-sort-menu-item-az"}
                      data-test={"browseScreen-sort-menu-item-az"}
                      name={"browseScreen-sort-menu-item-az"}
                      selected={props.sort === 'az'}
                      onClick={() => handleCloseSort("az")}>Title (A-Z)
                    </MenuItem>
                    <MenuItem
                      id={"browseScreen-sort-menu-item-za"}
                      data-test={"browseScreen-sort-menu-item-za"}
                      name={"browseScreen-sort-menu-item-za"}
                      selected={props.sort === 'za'}
                      onClick={() => handleCloseSort("za")}>Title (Z-A)
                    </MenuItem>
                  </Menu>) : null}
                </ButtonGroup>
                </ClickAwayListener>
              </Toolbar>
            </AppBar>)}
          </Grid>
        </Grid>
        {props.loading && <LinearProgress></LinearProgress>} {/* Pushed it inside the container - ARYAN */}
      </Container>

      <div className={ !minDisplay ? classes.resultsHeader : classes.resultsHeaderMin}>
          <InfiniteScroll
            dataLength={props?.results?.hits?.hits?.length ?? 0}
            hasMore={
              props?.results?.hits?.total?.value >
              props?.results?.hits?.hits?.length
            }
            next={props.loadMore}
            loader={
              <div className={classes.loadingContainer}>
                <CircularProgress
                  className={classes.progressBar}
                  size={80}
                ></CircularProgress>
              </div>
            }
            className={classes.infiniteScroll}
            // scrollableTarget="body"
          >
            <List variant="outlined" className={classes.resultsContainer}>{getItems()}
            </List>
          </InfiniteScroll>

        {previewItem && filesArr.length > 0 && (
          <AAISPDFViewerDialog
            files={filesArr}
            title={previewItem.publicationName}
            handleClose={() => resetPreview()}
          />
        )}
        {moreInformation && indexId && indexTitle && (
          <SingleResult
            indexId={indexId}
            title={indexTitle}
            category={indexCategory}
            handleClose={() => resetMoreInformation()}
            setUpNewTabLink={ async () => await setUpNewTabLink(indexFiles)}
          />
        )}
        {previewItem && thisFilesCountTop > 0 && validPDFsTop === false && chosenFile && (
          <FilePicker 
            setChosenFile={setChosenFile}
            handleClose={() => resetPreview()}
          />
        )}
        {showPopupBlockerWarning === true && (
          <PopUpWarning
            handleClose={() => setShowPopupBlockerWarning(false)}
          ></PopUpWarning>
        )}
      </div>
    </React.Fragment>
  );
}
