// Packages
import React, { useState, useEffect } from "react";

// MUI Components
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";


import DialogTitle from "@material-ui/core/DialogTitle";
//import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from "@material-ui/icons/Clear";

import { LinearProgress } from "@material-ui/core";

// Styles
import { useStyles } from "./styles";

// Services
import * as AssetApi from "apis/AssetApi";

import AAISPDFViewerTabs from './tabs.js';

export default function AAISPDFViewerDialog(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState();
  const [newFile, setNewFile] = useState(null);

  useEffect(() => {
    init();
  }, [props.files]);

  useEffect(() => {
    setLoading(true);
    async function fetchUrl() {
      if(newFile && newFile != null){
        const response = await AssetApi.getSignedUrlV1(newFile);
        const encodedUrl = response.url;//encodeURIComponent(response.url);
        setUrl(encodedUrl);
        setLoading(false);
      }
    }
    fetchUrl();
  }, [newFile]);

  

  async function init() {
    console.log(props);

    const files = props.files;
    if(files.length > 0){
      const response = await AssetApi.getSignedUrlV1(files[0]);
      const encodedUrl = response.url;//encodeURIComponent(response.url);
      setUrl(encodedUrl);
      setLoading(false);
    }
    
  }

  return (
    <Dialog open={true} onClose={props.handleClose} maxWidth="xl" fullWidth>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContentText className={classes.rightContent} onClick={props.handleClose} color="primary">
          <ClearIcon></ClearIcon>
      </DialogContentText>
      {props.files.length > 1 && (
        <AAISPDFViewerTabs
        files = {props.files}
        newFile = {newFile} 
        setNewFile = {setNewFile}
        ></AAISPDFViewerTabs>
      )}
      <DialogContent className={classes.content}>
        {loading && <LinearProgress />}
        {url && (
          <iframe
            src={url}
            height="98%"
            title={props.title}
            width="100%"
            type="application/pdf"
            allow="src"
            target="_self"
            referrerPolicy="unsafe-url"
          > <embed src={url} type="application/pdf"></embed> </iframe>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
