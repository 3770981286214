// Packages
import React, { useState, useEffect } from "react";

// MUI Components
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import FilePicker from "components/FilePicker";
import PopUpWarning from "components/PopUpWarning";

// Styles
import { useStyles } from "./styles";

// Data

import * as AssetApi from "apis/AssetApi";
import STATES from "enums/STATES";

//US125518 temporarily comment out data that is not ready to display

export default function MoreInfoTable(props) {
  const { infoEntries = [] } = props;
  const classes = useStyles();
  const [previewItem, setPreviewItem] = useState();
  const [chosenFile, setChosenFile] = useState(null);
  const [filesArr, setFilesArr] = useState([]);
  const [badFile, setBadFile] = useState(false);
  const [loadingGeneric, setLoadingGeneric] = useState(true);
  const [results, setResults] = useState();
  const [category, setCategory] = useState();
  const [flipper, setFlipper] = useState(true);
  const [foundGood, setFoundGood] = useState(false);
  const [showPopupBlockerWarning, setShowPopupBlockerWarning] = useState(false);
  const nowDate = new Date();


  useEffect(() => {
    parseResults(infoEntries);
  }, [infoEntries]);

  useEffect(() => {
    if(filesArr.length === 0 && previewItem && chosenFile === "True" && foundGood === false){
      setChosenFile("False");
      setBadFile(true);
    }
  }, [flipper]);

  function resetPreview(){
    setPreviewItem();
    setChosenFile(null);
    setFilesArr([]);
  }

  async function setupPreviewItem(){
    setChosenFile("True");
    const fixedArray = [];
    setBadFile(false);
    
    if( infoEntries && 'files' in infoEntries && infoEntries['files'] && infoEntries['files'].length > 0 ){
      const newUrls = {};
      await infoEntries['files'].map( async (fileObj) => {
        if(fileObj.extension && fileObj.extension === '.pdf'){
          setFoundGood(true);
          const response = await AssetApi.getSignedUrlV1(fileObj.filename);
          const encodedUrl = encodeURIComponent(response.url);
          fixedArray.push(fileObj.filename);
          //newUrls[fileObj.filename] = await window.open(`https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodedUrl}#zoom=auto#toolbar=0`, fileObj.filename);
          newUrls[fileObj.filename] = await window.open(`${response.url}#navpanes=0#zoom=auto#toolbar=0#&embedded=true`, fileObj.filename);
          if(!newUrls[fileObj.filename] || newUrls[fileObj.filename].closed || typeof newUrls[fileObj.filename]=='undefined') 
          { 
              setShowPopupBlockerWarning(true);
          }
        }
      });
    }
    setFilesArr(fixedArray);
    setPreviewItem(infoEntries);
    setFlipper(!flipper);
  }

  async function setupBulletinItem(source){
    setChosenFile("True");
    const fixedArray = [];
    setBadFile(false);
    
    if( source && 'files' in source && source['files'] && source['files'].length > 0 ){
      await source['files'].map( async (fileObj) => {
        if(fileObj.extension && fileObj.extension === '.pdf'){
          setFoundGood(true);
          const response = await AssetApi.getSignedUrlV1(fileObj.filename);
          const encodedUrl = encodeURIComponent(response.url);
          fixedArray.push(fileObj.filename);
          //window.open(`https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodedUrl}#zoom=auto#toolbar=0`, fileObj.filename);
          window.open(`${response.url}#navpanes=0#zoom=auto#toolbar=0#&embedded=true`, fileObj.filename);
        }
      });
    }
    setFilesArr(fixedArray);
    setPreviewItem(source);
    setFlipper(!flipper);
  }

  async function openBulletinLink(bulletinId){
    const bulletinData = await AssetApi.get(bulletinId);
    await setupBulletinItem(bulletinData['_source']);
  }


  function parseResults(infoEntries){
    const newResults = [];
    const lobsUnsorted = infoEntries.lobs;
    const files = infoEntries.files;
    setCategory(infoEntries.publicationTypeCategory);
    const statesArr = Object.values(STATES);

    const lobs = lobsUnsorted.sort((a, b) => {
      const nameA=a.abbreviation.toLowerCase(), nameB=b.abbreviation.toLowerCase();
      if (nameA < nameB){
          return -1;
      }
      if (nameA > nameB){
          return 1;
      }
      return 0;
  });


    for (let i = 0; i < lobs.length; i++) {
      const lobsData = [];
      const states = lobs[i].states;
      for (let j = 0; j < states.length; j++) {
        const temp = {};
        temp['line'] = lobs[i].abbreviation;
        let thisState = '-';
        let thisBulletin = [];
        let thisSerff = '-';
        let thisFileNumber = '-';
        for (let k = 0; k < statesArr.length; k++) {
          if(states[j].abbreviation === statesArr[k].key){
            thisState = statesArr[k].title;
          }
        }

        if(states[j].bulletinNumbers !== null){
          thisBulletin = states[j].bulletinNumbers;
        }
        if(states[j].serffTrackingNumbers !== null){
          thisSerff = states[j].serffTrackingNumbers.join(', ');
        }
        if(states[j].filingNumbers !== null){
          thisFileNumber = states[j].filingNumbers.join(', ');
          while(thisFileNumber.includes(', ,')){
            thisFileNumber = thisFileNumber.replace(/, ,/g, ',');
          }
          while(thisFileNumber.includes(',,')){
            thisFileNumber = thisFileNumber.replace(/,,/g, ',');
          }
        }

        temp['state'] = thisState;
        temp['from'] = states[j].effectiveDate;

        let displayToDate = states[j].expirationDate;
        const toDate = new Date(displayToDate);
        if (toDate > nowDate) {
          displayToDate = '-';
        }

        temp['to'] = displayToDate;
        temp['form'] = infoEntries.documentNumber;
        temp['edition'] = infoEntries.edition;
        temp['title'] = infoEntries.displayName;
        temp['category'] = infoEntries.publicationTypeCategory;
        temp['bulletin'] = thisBulletin;
        temp['fileNum'] = thisFileNumber;//infoEntries.publicationId;
        temp['serff'] = thisSerff;
        temp['stateFile'] = '-';//thisStateFile;
        temp['replaced'] = "-";

        lobsData.push(temp);
      }
      const sortedLobsData = lobsData.sort((a, b) => {
          const nameA=a.state.toLowerCase(), nameB=b.state.toLowerCase();
          if (nameA < nameB){
              return -1 ;
          }
          if (nameA > nameB){
              return 1;
          }
          return 0;
      });
      sortedLobsData.filter((item) => {
        newResults.push(item);
      });


    }
    setLoadingGeneric(false);
    setResults(newResults);
    props.updateTopResults(newResults);
  }

  function getTableRow(data) {
    if (!data) return null;
    let formReactData = null;
    let formAndIMGDataBull = null;
    let formAndIMGDataMRR = null;
    if(category === 'Forms'){
      formReactData = 
      <React.Fragment>
      <TableCell component="th" scope="row">
            <Typography variant="body1">
            {data.fileNum}
            </Typography>
          </TableCell>
          <TableCell component="th" scope="row">
            <Typography variant="body1">
            {data.serff}
            </Typography>
          </TableCell>

      </React.Fragment>;// <TableCell component="th" scope="row"> <Typography variant="body1"> {data.stateFile} </Typography>  </TableCell> // US125518
    }
    if(category === 'Forms' || category === 'IMG Publications'){

      formAndIMGDataBull = <React.Fragment>
         <TableCell component="th" scope="row">
            <Typography variant="body1">
            {data.bulletin.map((bulletin) => (
              <Typography className={classes.urlCell} key={bulletin.bulletinNumber} variant="body1"  onClick={ async() => await openBulletinLink(bulletin.id)} >
                <span key={bulletin.id}>{bulletin.bulletinNumber}</span>
              </Typography>
            ))}
            </Typography>
          </TableCell>
      </React.Fragment>;

      formAndIMGDataMRR = <React.Fragment>
      <TableCell component="th" scope="row">
         <Typography variant="body1">
         {data.replaced}
         </Typography>
       </TableCell>
      </React.Fragment>;
    }

    const reactData = 
      <React.Fragment key={`${data.line}-${data.state}-section`}>
        <TableRow
          key={data.line + data.state}
          hover={true}
          data-test={"rowDisplay-" + data.line + data.state}
          className={classes.tr}
        >
          <TableCell component="th" scope="row">
            <Typography variant="body1">
            {data.line}
            </Typography>
          </TableCell>
          <TableCell component="th" scope="row">
            <Typography variant="body1">
            {data.state}
            </Typography>
          </TableCell>
          <TableCell component="th" scope="row">
            <Typography variant="body1">
            {data.from}
            </Typography>
          </TableCell>
          <TableCell component="th" scope="row">
            <Typography variant="body1">
            {data.to}
            </Typography>
          </TableCell>
          {/*formAndIMGDataBull US125518*/}
          {formReactData}
          {/*formAndIMGDataMRR US125518*/}
        </TableRow>
      </React.Fragment>;
    return (reactData);
  }


  let formReactTHData = null;
  const formAndIMGReactTHDataBull = null;//let US125518
  const formAndIMGReactTHDataMRR = null;//let US125518
    if(category === 'Forms'){
      formReactTHData = 
      <React.Fragment>
        <TableCell>
          <Typography>AAIS File Number</Typography>
        </TableCell>
        <TableCell>
          <Typography>Serff Tracking Number</Typography>
        </TableCell>
      </React.Fragment>;//<TableCell> <Typography>State File Number</Typography> </TableCell>
    }//Turn OFF until data is ready US125518
    /*if(category === 'Forms' || category === 'IMG Publications'){
      formAndIMGReactTHDataBull = <React.Fragment>
        <TableCell>
          <Typography>Bulletin Number</Typography>
        </TableCell>
      </React.Fragment>;

      formAndIMGReactTHDataMRR = <React.Fragment>
        <TableCell>
          <Typography>Most Recently Replaced</Typography>
        </TableCell>
      </React.Fragment>;
    }*/

  return (
    <React.Fragment className={classes.topTable}>
        {loadingGeneric && <LinearProgress data-testid="progressBar" />}
        {results && (
          <Paper>
            <Table className={classes.table}>
              <TableHead className={classes.th}>
                <TableRow>
                  <TableCell>
                    <Typography>Line</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>State</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>From</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>To</Typography>
                  </TableCell>
                  {formAndIMGReactTHDataBull}
                  {formReactTHData}
                  {formAndIMGReactTHDataMRR}
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((result) => {
                  return getTableRow(result);
                })}
              </TableBody>
            </Table>
          </Paper>
        )}
        {previewItem && chosenFile === 'False' && filesArr.length === 0 && badFile === true && (
            <FilePicker 
              setChosenFile={setChosenFile}
              handleClose={() => resetPreview()}
            />
          )}
        {showPopupBlockerWarning === true && (
          <PopUpWarning
            handleClose={() => setShowPopupBlockerWarning(false)}
          ></PopUpWarning>
        )}

    </React.Fragment>
  );
}